<template>
  <section>
    <div class="topup-modal">
      <b-steps
        v-model="activeStep"
        :rounded="isRounded"
        :has-navigation="hasNavigation"
        :icon-prev="prevIcon"
        :icon-next="nextIcon"
        :clickable="isStepsClickable"
        :label-position="labelPosition"
        :mobile-mode="mobileMode"
      >
        <b-step-item
          step="1"
          :clickable="isStepsClickable"
        >
          <h1 class="title has-text-centered">ข้อมูลบัญชีที่ต้องการโอน</h1>
          <div class="profile-summary-left">
            <div class="profile-summary-box">
              <div class="profile-summary-detail">
                <img
                  class="is-rounded profile-image"
                  :src="data.profile_image_url"
                  loading="lazy"
                  alt="profile image"
                  @error="fallbackProfileImage"
                >
                <div class="profile-summary-profile">
                  <div>Name: {{ data.display_name }}</div>
                  <div>Tag name: @{{ data.tag_name }}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="columns">
            <div class="column is-two-thirds">
              <div class="buttons">
                <b-button
                  v-for="p in packages"
                  :key="p.id"
                  size="is-large"
                  type="is-primary"
                  outlined
                  @click="currencyTransferAmount = p.amount"
                >
                  {{ p.amount }}
                </b-button>
              </div>
              <b-field>
                <b-numberinput
                  v-model="currencyTransferAmount"
                  :min="1"
                  :max="currency"
                  size="is-medium"
                />
              </b-field>
            </div>
            <div class="currency-field">
              <div class="column">
                <div class="profile-stats">
                  <div class="profile-stats-box">
                    <div class="has-text-centered">
                      <div>
                        <div class="heading">จำนวน Coin ที่สามารถโอนได้</div>
                        <div class="title">
                          {{ formatNumberCommas(currency) }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-step-item>

        <b-step-item
          step="2"
          :clickable="isStepsClickable"
          :type="{ 'is-success': isProfileSuccess }"
        >
          <h1 class="title has-text-centered">สรุปข้อมูลการโอน</h1>
          <div class="columns">
            <div class="column">
              <div class="profile-summary-left">
                <div class="profile-summary-box">
                  <div class="profile-summary-title">
                    ข้อมูลบัญชีที่ต้องการโอน
                  </div>
                  <div class="profile-summary-detail">
                    <img
                      class="is-rounded profile-image"
                      :src="data.profile_image_url"
                      loading="lazy"
                      alt="profile image"
                      @error="fallbackProfileImage"
                    >
                    <div class="profile-summary-profile">
                      <div>Name: {{ data.display_name }}</div>
                      <div>Tag name: @{{ data.tag_name }}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="column">
              <div class="currency-field">
                <div class="column">
                  <div class="profile-stats">
                    <div class="profile-stats-box">
                      <div class="has-text-centered">
                        <div>
                          <div class="heading">จำนวน Coin ที่ต้องการโอน</div>
                          <div class="title">
                            {{ formatNumberCommas(currencyTransferAmount) }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-step-item>

        <b-step-item
          step="3"
          :clickable="isStepsClickable"
          :type="{ 'is-success': isProfileSuccess }"
        >
          <div class="success-page">
            <img
              v-if="verify.type === 'approved'"
              src="@/assets/success.svg"
              alt="approved"
              class="alert-icon"
            >
            <img
              v-if="verify.type === 'failed'"
              src="@/assets/failed.svg"
              alt="failed"
              class="alert-icon"
            >
            <h2>{{ verify.title }}</h2>
            <p>{{ verify.description }}</p>
            <div class="success-page-footer">
              <div
                v-if="verify.type === 'approved'"
                class="columns"
              >
                <div class="column">
                  <div class="profile-summary-left">
                    <div class="profile-summary-box">
                      <div class="profile-summary-title">
                        ข้อมูลบัญชีที่ทำการโอน
                      </div>
                      <div class="profile-summary-detail">
                        <img
                          class="is-rounded profile-image"
                          :src="data.profile_image_url"
                          loading="lazy"
                          alt="profile image"
                          @error="fallbackProfileImage"
                        >
                        <div class="profile-summary-profile">
                          <div>Transfer ID: {{ receipt.trans_id }}</div>
                          <div>Transfer Date: {{ getLocalTime(new Date(receipt.trans_created_at).toLocaleString()) }}</div>
                          <div>Display Name: {{ data.display_name }}</div>
                          <div>Tag Name: {{ data.tag_name }}</div>
                          <div>UID: {{ receipt.to_user_uid }}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="column">
                  <div class="currency-field">
                    <div class="column">
                      <div class="profile-stats">
                        <div class="profile-stats-box">
                          <div class="has-text-centered">
                            <div>
                              <div class="heading">จำนวน Coin ที่โอน</div>
                              <div class="title">
                                {{ formatNumberCommas(currencyTransferAmount) }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="buttons">
                <b-button
                  size="is-large"
                  type="is-primary"
                  @click="$parent.close()"
                >
                  Close
                </b-button>
              </div>
            </div>
          </div>
        </b-step-item>

        <template
          v-if="customNavigation"
          #navigation="{ previous }"
        >
          <div class="topup-button-container">
            <b-button
              v-if="activeStep == 1"
              type="is-light"
              :disabled="previous.disabled"
              @click.prevent="previous.action"
            >
              Back
            </b-button>
            <b-button
              v-if="activeStep == 0"
              type="is-primary"
              :loading="processing"
              :disabled="
                (currencyTransferAmount < 1 || currencyTransferAmount > currency)
              "
              @click="
                activeStep = activeStep + 1
              "
            >
              Next
            </b-button>
            <b-button
              v-if="activeStep == 1"
              type="is-primary"
              :loading="processing"
              :disabled="
                processing === true
              "
              @click="
                activeStep == 1 && processing === false ? submitTopup() : (activeStep = activeStep + 1)
              "
            >
              Next
            </b-button>
          </div>
        </template>
      </b-steps>
    </div>
  </section>
</template>
<script>
import { format } from 'date-fns'
import { formatCreatedTime, formatNumberCommas } from '@/utils'
import updateCurrencyTopup from '@/graphql/updateCurrencyTopup.gql'
import updateCurrencyTopupForAdmin from '@/graphql/updateCurrencyTopupForAdmin.gql'
import { mapGetters } from 'vuex'
export default {
  name: 'TopupModal',
  props: {
    data: {
      type: Object,
      default: null
    },
    currency: {
      type: Number,
      default: 0
    },
    packages: {
      type: Array,
      default: null
    },
    admin: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      processing: false,
      activeStep: 0,
      showSocial: false,
      isAnimated: true,
      isRounded: true,
      isStepsClickable: false,
      hasNavigation: true,
      customNavigation: true,
      isProfileSuccess: false,
      prevIcon: 'chevron-left',
      nextIcon: 'chevron-right',
      labelPosition: 'bottom',
      mobileMode: 'minimalist',
      currencyTransferAmount: 0,
      receipt: null,
      verify: {
        type: 'failed',
        title: 'มีข้อผิดพลาด',
        description: 'ไม่สามารถโอน coin ได้'
      }
    }
  },
  computed: {
    ...mapGetters({
      user: 'user',
      role: 'role'
    })
  },
  methods: {
    async submitTopup () {
      const isAdmin = this.role ? this.role.includes('admin') : false
      console.log(isAdmin && this.admin)
      if (
        this.currencyTransferAmount > 0 &&
        this.currencyTransferAmount <= this.currency
      ) {
        try {
          this.processing = true
          await this.$apollo.mutate({
            mutation: isAdmin === true && this.admin === true ? updateCurrencyTopupForAdmin : updateCurrencyTopup,
            variables: {
              currency_id: 1,
              amount: this.currencyTransferAmount,
              to_user_uid: this.data.uid
            },
            update: (cache, { data }) => {
              if (data.transfer_currency.amount) {
                this.$emit('topupVerify', true)
                this.receipt = data.transfer_currency
                this.verify = {
                  type: 'approved',
                  title: 'โอน Coin สำเร็จ',
                  description: `คุณได้ทำการโอน Coin จำนวน ${this.formatNumberCommas(
                    data.transfer_currency.amount
                  )} ให้กับ @${
                    this.data.tag_name
                  } เรียบร้อย กรุณาตรวจสอบรายละเอียด`
                }
              }
            }
          })
        } catch (error) {
          this.verify = {
            type: 'failed',
            title: 'มีข้อผิดพลาด',
            description: 'ไม่สามารถโอน coin ได้ กรุณาลองใหม่อีกครั้ง'
          }
        } finally {
          this.processing = false
          this.activeStep = this.activeStep + 1
        }
      }
    },
    getLocalTime (isotime) {
      return format(new Date(isotime), 'dd/MM/yyyy HH:mm:ss')
    },
    fallbackProfileImage (e) {
      e.target.src = 'https://space.pillowcast.app/image/default/default.svg'
    },
    formatCreatedTime (isotime) {
      return formatCreatedTime(isotime)
    },
    formatNumberCommas (number) {
      return formatNumberCommas(number)
    }
  }
}
</script>
<style lang="scss" scoped>
.profile-stats {
  border: 1px solid #424e5b;
  padding: 0.5rem;
  border-radius: 8px;
  .profile-stats-box {
    padding: 0.5rem 0;
    margin: 0;
    .title {
      color: #e2aa04;
    }
    .title-balance {
      color: #55d9c2;
      font-size: 2rem;
      font-weight: bold;
    }
    .heading {
      font-size: 0.875rem;
      color: #fff;
      text-transform: capitalize;
    }
  }
  .profile-data {
    color: #b5b5b5;
    border-bottom: 1px solid #424e5b;
    padding: 0 0 1rem 0;
    img {
      border-radius: 50%;
    }
  }
}

.success-page {
  text-align: center;
  padding: 2rem 0;
  h2 {
    color: $font-color;
    font-size: 2rem;
    text-align: center;
  }
  p {
    color: darken($font-color, 30%);
    text-align: center;
  }
  .success-page-footer {
    padding-top: 1.5rem;
    text-align: center;
  }
}

.topup-modal {
  background-color: rgba(28, 39, 51, 1);
  // margin: 20px;
  padding: 20px;
  min-height: 300px;
  text-align: center;
  border-radius: 12px;
  border: 1px solid #1c2733;
  h1 {
    color: $font-color;
    font-size: 1.75rem;
    text-align: center;
    margin-bottom: 10px;
  }
  .profile-detail {
    border-bottom: 1px solid #424e5b;
    padding: 0 0 1rem 0;
    margin: 0 0 1.5rem 0;
    .profile-title {
      font-size: 1.75rem;
    }
  }

  .profile-summary-left {
    padding: 0.75rem 0 0.75rem 0;
  }

  .profile-summary-box {
    border: 1px solid #424e5b;
    padding: 0.5 0 0.5rem 0;
    border-radius: 8px;
  }

  .profile-summary-title {
    font-size: 1.25rem;
    display: flex;
    border-bottom: 1px solid #424e5b;
    padding: 0.5rem;
    @media only screen and (max-width: 767px) {
      justify-content: center;
    }
  }
  .profile-summary-detail {
    display: flex;
    padding: 0.5rem 1rem 1rem 0.5rem;
    align-items: center;
    @media only screen and (max-width: 767px) {
      flex-direction: column;
    }

    .profile-summary-profile {
      display: flex;
      flex-direction: column;
      padding: 0 0 0 1rem;
      text-align: left;
      @media only screen and (max-width: 767px) {
        text-align: center;
        padding: 0rem;
      }
    }
  }

  .topup-button-container {
    display: flex;
    justify-content: flex-end;
    .button {
      margin: 0 0.5rem 0 0;
    }
  }

  .currency-field {
    width: 100%;
  }

  .buttons {
    display: flex;
    justify-content: space-between;
    flex-basis: auto;
    .button {
      flex: 1;
    }
  }

  .app-icon {
    height: 100px;
    width: 100px;
  }
  .action-button {
    padding: 12px 0;
  }
}
</style>
